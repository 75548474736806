import React, { useState, useEffect } from "react";
import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import "react-web-tabs/dist/react-web-tabs.css";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { FeatureArrowWithColor } from "../../components/common/Icons";
import { useMedia } from "use-media";

const w_img = require("../../assets/img/home_page_assist/single-webi.png");
const play_img = require("../../assets/img/home_page_assist/play_icon_webinar.svg");
const sneha = require("../../assets/img/home_page_assist/sneha.png");
const avinash = require("../../assets/img/home_page_assist/avinash.png");
const card_bottom_2 = require("../../assets/img/integration/bc_1_2.png");

export default function chatbotWebinar() {
  return (
    <>
      <TitleAndMetaTags
        title="Free Bamboo HR Chatbot Integration | How to build Bamboo HR AI chatbot and tasks using Conversational AI Platform"
        description="Learn how to build Bamboo HR chatbot in Slack and Teams using Conversational AI Platform Create, update, and modify Bamboo HR tickets from chatbot and automate your workplace support"
        keywords={[
          "HR chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "helpdesk chatbot free trial",
          "automation",
        ]}
      />
      <section className="webinar-utube-container">
        <Container>
          <Layout backgroundColor={"landing_bg"} logoFor="ASSISTANT">
            <WebinarVideosGrid />
            <WebinarResources />
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

export const WebinarVideosGrid = () => {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left webinar-single-container">
      <div className="container">
        {webinarData.map((data, index) => {
          const {
            singleHeader,
            singleImage,
            singleDate,
            speakers,
            singleSubHeader,
            list,
          } = data;

          const speakerData = speakersArray
            .filter((speakerObj) => speakers.includes(speakerObj.id))
            .sort((a, b) => {
              const indexA = speakers.indexOf(a.id);
              const indexB = speakers.indexOf(b.id);
              return indexA - indexB;
            });

          return (
            <div className="web-single-flex-container" key={index}>
              <div className="webinar-single-left-flex">
                <h5 className="font-section-normal-text-medium webinar-back-button">
                  {" "}
                  <FeatureArrowWithColor />
                  <span className="">Back</span>
                </h5>
                <h1
                  className={`font-section-sub-header-bold ${
                    isSmall ? "mb-3" : "mb-2"
                  }`}
                >
                  {singleHeader}
                </h1>
                <div className="ind_image_web_container w-100">
                  <img src={singleImage} alt="Webinar" className="opacity-06" />
                  <div className="play_webinar_div">
                    <img src={play_img}></img>
                  </div>
                </div>
                <h4 className="font-name-big-normal-text mb-0 mt-3">
                  {singleDate}
                </h4>
              </div>
              <div className="webinar-single-right-flex">
                <h3 className="font-section-sub-header-small-home">Speakers</h3>
                <div className="webinar-speaker-container">
                  {speakerData.slice(0, 2).map((speaker, speakerIndex) => (
                    <div className="speaker" key={speakerIndex}>
                      <img src={speaker.image} alt={speaker.name} />
                      <h5 className="font-name-big-normal-text-medium mb-0">
                        {speaker.name}
                      </h5>
                      <p className="font-section-small-text mb-0 pb-0">
                        {speaker.bio}
                      </p>
                    </div>
                  ))}
                </div>
                <h5 className="font-section-normal-text-medium webinar-agenda">
                  Agenda
                </h5>
                <h4 className="font-section-normal-text-medium mb-0">
                  {singleSubHeader}
                </h4>
                <ul className="font-section-normal-text-webinar-ul float-left w-100 blogs_ul_upd webinar-ul">
                  {list.split(".").map((item, itemIndex) => {
                    const trimmedItem = item.trim();
                    if (trimmedItem !== "") {
                      return <li key={itemIndex}>{trimmedItem}</li>;
                    }
                    return null;
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export const webinarData = [
  {
    singleHeader: "IT Support Automation: Increase ROI & Reduce costs",
    singleImage: w_img,
    singleDate: "Thursday, 20 April 2023",
    speakers: ["sneha", "avinash"],
    singleSubHeader: "In this webinar we will explore the following topics:",
    list:
      "Best practices and real-world examples of how IT support automation can transform your IT operations.​Learn how automation can help you resolve issues faster, reduce calls, and free up your IT support team to focus on important issues. Discover how it can increase your ROI by optimizing resource utilization, enhancing employee satisfaction, and reducing MTTR​.",
    resourceData: [
      {
        resourceImage: card_bottom_2,
        resourceContent:
          "Browse through topics on AI, Automation, Chatbots and Workplace support.",
        resourceLink: "Read Blog",
        resourceUrl: "workativ.com",
      },
      {
        resourceImage: card_bottom_2,
        resourceContent:
          "What’s the real value for business in transforming workplace support?",
        resourceLink: "Read Whitepaper",
        resourceUrl: "workativ.com",
      },
      {
        resourceImage: card_bottom_2,
        resourceContent:
          "Leapfrog with Digital Assistants that enable business continuity during COVID-19",
        resourceLink: "Learn More",
        resourceUrl: "workativ.com",
      },
    ],
  },
  // Add more objects to mainArray as needed
];

const speakersArray = [
  {
    id: "avinash",
    name: "Avinash",
    bio: "Digital Marketing Head",
    image: avinash,
  },
  {
    id: "sneha",
    name: "Sneha Dolare",
    bio: "Sales Leader",
    image: sneha,
  },
  {
    id: "imad",
    name: "Sneha Dolare",
    bio: "Sales Leader",
    image: sneha,
  },
  {
    id: "vinod",
    name: "Sneha Dolare",
    bio: "Sales Leader",
    image: sneha,
  },
  {
    id: "imran",
    name: "Sneha Dolare",
    bio: "Sales Leader",
    image: sneha,
  },
];

const WebinarResources = () => {
  return (
    <section className="w-100 float-left webinar-resources-main-container">
      <div className="container">
        <h2 className="font-section-sub-header text-align-center mb-3">
          Resources
        </h2>
        <div className="webinar-resources-flex">
          {webinarData[0].resourceData.map((data, index) => {
            const {
              resourceImage,
              resourceContent,
              resourceLink,
              resourceUrl,
            } = data;

            return (
              <div className="webinar-resource-box" key={index}>
                <div className="resource-box-up">
                  <img src={resourceImage} alt="Resource" />
                </div>
                <div className="resource-box-down">
                  <p className="font-name-big-normal-text-medium resource-content-webinar line-height-15">
                    {resourceContent}
                  </p>
                  <a
                    className="font-name-big-normal-text-medium"
                    href={resourceUrl}
                  >
                    {resourceLink} &#8594;
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
